/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('is-external').attr('target', '_blank');

                // Toggle
                // Navigation
                $('.js-toggle-nav').click(function(e) {
                    e.preventDefault();

                    $('.c-header').toggleClass('is-open');
                });

                // Dropdown
                $('.js-toggle-dropdown').click(function() {
                    $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
                });

                // More
                $('.js-toggle-more').click(function(e) {
                    var buttonText = $(this).next('.c-section__more').is(':visible') ? 'Read More' : 'Read Less';

                    $(this).text(buttonText);

                    $(this).next('.c-section__more').slideToggle();
                });

                // Lightbox - Images
                if (viewportWidth >= 1024) {
                    // Fancybox
                    $('.js-lightbox-image').attr('data-fancybox', 'group').fancybox();
                }

                // Header
                var header          = $('.c-header'),
                    headerHeight    = header.outerHeight(true);

                if (!$('body.home').length) {
                    header.addClass('is-fixed');

                    $('body').css('padding-top', header.outerHeight(true));
                }

                // Page State
                $('a').each(function() {
                    if (location.hostname === this.hostname || !this.hostname.length) {
                        var link = $(this).attr('href');

                        if (link.match('^/app/uploads')) {
                            // Default behaviour
                        } else if (link.match('^#')) {
                            // Defaul behaviour
                            $(this).click(function(e) {
                                e.preventDefault();
                            });
                        } else if (!link.match('^mailto') && !link.match('^tel')) {
                            $(this).click(function(e) {
                                e.preventDefault();

                                $('html').addClass('is-fading-out');

                                setTimeout(function() {
                                    window.location = link;
                                }, 500);
                            });
                        }
                    }
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // Counter
                if ($('.c-counter').length) {
                    var counter     = $('.c-counter'),
                        counterItem = $('.c-counter__item'),
                        counterTop  = counter.offset().top;

                    $(window).scroll(function() {
                        var scrollY         = $(this).scrollTop(),
                            windowBottom    = (scrollY + $(this).innerHeight());

                        if (windowBottom >= counterTop) {
                            counter.addClass('is-animated');

                            counterItem.each(function() {
                                var counterText = $(this).children('.c-counter__number');

                                $(this).prop('counter', 0).animate({
                                    counter: $(this).data('number')
                                }, {
                                    duration: 1500,
                                    easing: 'swing',
                                    step: function(now) {
                                        counterText.text(Math.ceil(now));
                                    }
                                });
                            });
                        }
                    });
                }
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                var viewportWidth = $(window).width();

                // Header
                var header          = $('.c-header'),
                    headerTop       = header.offset().top,
                    headerHeight    = header.outerHeight(true);

                $(window).scroll(function() {
                    var scrollY = $(this).scrollTop();

                    if (scrollY >= headerTop) {
                        header.addClass('is-fixed');

                        $('body').css('padding-top', header.outerHeight(true));
                    } else {
                        header.removeClass('is-fixed');

                        $('body').css('padding-top', 0);
                    }
                });

                // Services
                if (viewportWidth >= 1024) {
                    if ($('.c-services').length) {
                        var services = $('.c-services'),
                            servicesTop = services.offset().top;

                        $(window).scroll(function() {
                            var scrollY         = $(this).scrollTop(),
                                windowBottom    = (scrollY + $(this).innerHeight());

                            if (windowBottom >= servicesTop) {
                                services.addClass('is-animated');
                            }
                        });
                    }
                }

                // Slick
                if ($('.js-carousel-portfolio').length) {
                    $('.js-carousel-portfolio').slick({
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 6000,
                        dots: false,
                        prevArrow: '<button class="slick-prev" type="button"></button>',
                        nextArrow: '<button class="slick-next" type="button"></button>',
                        rows: 2,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        responsive: [
                            {
                                breakpoint: 1023,
                                settings: {
                                    rows: 1,
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    });
                }

                if ($('.js-carousel-testimonials').length) {
                    $('.js-carousel-testimonials').slick({
                        adaptiveHeight: true,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        arrows: true,
                        dots: false,
                        pauseOnHover: true,
                        prevArrow: '<button class="slick-prev" type="button"></button>',
                        nextArrow: '<button class="slick-next" type="button"></button>',
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }

                if ($('.js-carousel-partners').length) {
                    $('.js-carousel-partners').slick({
                        autoplay: true,
                        autoplaySpeed: 7000,
                        arrows: true,
                        dots: false,
                        pauseOnHover: true,
                        prevArrow: '<button class="slick-prev" type="button"></button>',
                        nextArrow: '<button class="slick-next" type="button"></button>',
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        responsive: [
                            {
                                breakpoint: 769,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 415,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    });
                }
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Why Choose Us page, note the change from why-choose-us to why_choose_us.
        'why_choose_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
